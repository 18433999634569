<template>
  <div>
    <div>
      <vx-card
        class="mb-4"
        :title="`📦📥 Asignaciónes masivas de ${$tc(
          'message.warehouse',
          1,
          user.countryCode
        )} a propiedad en ${project.name}`"
        title-color="black"
      >
        <p class="mb-2">
          Utiliza el archivo base para la relacion de
          {{ $tc("message.warehouse", 1, user.countryCode) }} con la unidad,
          <br />
          no te preocupes en el archivo encontraras un ejemplo.
        </p>
        <vs-button
          color="primary"
          icon-pack="fas"
          icon="fa-file-excel"
          class="mt-4"
          href="https://flattlo-app.s3.amazonaws.com/base-download-files/Relacion_bodegas_Flattlo.xlsx"
          type="flat"
          >&nbsp;Descargar archivo base</vs-button
        >
      </vx-card>
      <ExcelBatchImporter :onSuccess="loadDataInTable" />
    </div>
    <vx-card
      v-if="tableData.length && header.length"
      title="📁📃 Carga correcta."
      title-color="black"
    >
      <p>
        Se han encontrado <strong>{{ tableData.length }}</strong> registros.
      </p>
      <div class="mt-2">
        <p>
          Seleccione el tipo de propiedad padre de los siguientes registros:
        </p>
        <div>
          <label
            for="location"
            class="block text-sm font-medium text-gray-700 mt-2"
            >Tipo de propiedad</label
          >
          <select
            id="select-prop"
            name="location"
            v-model="principalTypeProperty"
            class="mt-1 block pl-3 pr-10 py-2 text-lg border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg rounded-md"
          >
            <option value="APARTMENT">APARTAMENTO</option>
            <option selected value="PARKING">PARQUEO</option>
          </select>
        </div>
      </div>
      <vs-button
        @click="uploadWarehouses(principalTypeProperty)"
        color="success"
        class="mt-4"
        type="filled"
        icon-pack="fas"
        icon="fa-wave-square"
        >Generar asignaciones</vs-button
      >
      <br />
      <vs-progress
        v-if="statusBar > 0 && dataIsLoaded"
        :height="12"
        :percent="statusBar"
        color="success"
      ></vs-progress>
      <p v-if="statusBar > 0 && dataIsLoaded">
        Importado correcto de <strong>{{ statusBar }}%</strong>
      </p>
    </vx-card>
    <br />

    <vx-card v-if="error" title="❌ Error." title-color="black">
      Las siguientes filas contienen errores o no están disponibles:
      <vx-list :list="this.dataWithErrors"> </vx-list>
    </vx-card>
    <br />

    <vx-card v-if="tableData.length && header.length">
      <vs-table stripe :max-items="50" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{
            heading
          }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="col" v-for="col in data[indextr]" :key="col">
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <br />
    <div>
      <div>
        <vs-row class="px-4" vs-type="flex" vs-lg="12" vs-sm="12" vs-xs="12">
          <ListAssignedWarehouse />
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ExcelBatchImporter from "../../../components/ExcelBatchImporter.vue";
import VxList from "../../../components/vx-list/VxList.vue";
import ListAssignedWarehouse from "./ListAssignedWarehouse";
import { propertyApi } from "../services";
import { api } from "../../projects/services";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  components: {
    ExcelBatchImporter,
    VxList,
    ListAssignedWarehouse,
  },
  data() {
    return {
      tableData: [],
      header: [],
      sheetName: "",
      statusBar: 0,
      error: false,
      dataWithErrors: [],
      dataIsLoaded: false,
      project: null,
      principalTypeProperty: "PARKING",
    };
  },
  async mounted() {
    const projectId = this.$store.state.project.currentProjectId;
    this.project = await api.get(projectId);

    this.$store.state.project.breadcrumbs = [
      { pageName: 'Edición de proyectos', route: '/proyectos' },
      {
        pageName: this.capitalizeFirstLetter +  ` en ${this.project.name}`,
        route: `/bodegas/${projectId}`
      },
      {
        pageName: 'Asignación de ' +  this.$tc('message.warehouse',2,this.user.countryCode),
        route: `/importador-de-bodegas-con-asignacion`
      }
    ];

  },
  computed: {
    ...mapState("auth", ["user"]),
    capitalizeFirstLetter() {
      let str = this.$tc('message.warehouse',2,this.user.countryCode);
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  methods: {
    loadDataInTable({ results, header, meta }) {
      this.header = header;
      this.tableData = results;
      this.sheetName = meta.sheetName;
    },
    async uploadWarehouses(mainPropertyType) {
      try {
        this.error = false;
        this.dataWithErrors = [];
        const projectId = this.$store.getters["project/currentProject"];
        const batchQuery = await propertyApi.getBatchProportiesByUnitCode(
          this.tableData,
          projectId,
          "WAREHOUSE",
          mainPropertyType
        );
        const arrayBatchQuery = Object.entries(batchQuery);

        const itemsNoCompleted = arrayBatchQuery.filter((item) => {
          const [row, data] = item;
          if (data.length < 2) return [row, data];
        });

        if (itemsNoCompleted.length === 0) {
          this.dataIsLoaded = true;
          this.statusBar = 0;
          const statusBarIncrement =
            Math.round((100 / arrayBatchQuery.length) * 100) / 100;
          const propertiesToAssign = arrayBatchQuery.map(async (item) => {
            const [, values] = item;
            const [propertyIndex1, propertyIndex2] = values;
            let payload;
            if (propertyIndex1.property_type_id === mainPropertyType)
              payload = {
                property_id: propertyIndex1.id,
                property_assigned_id: propertyIndex2.id,
              };
            else if (propertyIndex2.property_type_id === mainPropertyType)
              payload = {
                property_id: propertyIndex2.id,
                property_assigned_id: propertyIndex1.id,
              };

            try {
              await propertyApi.allocateProperty(
                payload.property_id,
                payload.property_assigned_id
              );
            } catch (error) {
              console.error(`Error con la propiedad ${payload.property_id}`);
              console.error(error);
            }
            this.statusBar =
              Math.round((this.statusBar + statusBarIncrement) * 100) / 100;
          });

          await Promise.all(propertiesToAssign);

          this.statusBar = 100;
          this.$vs.notify({
            time: 6000,
            title: `Batch importado! 🏢🚀`,
            text: `Las propiedades fueron asignadas correctamente.`,
            color: "success",
          });
          this.tableData = [];
          this.statusBar = 0;
          this.principalTypeProperty = "PARKING";
        } else {
          this.error = true;
          this.dataIsLoaded = false;
          this.dataWithErrors = itemsNoCompleted.map((item) => {
            return item[0];
          });
        }
      } catch (e) {
        sentryCaptureException(e, "warehouse-assigned-importer");
        this.statusBar = 0;
        this.$vs.notify({
          time: 6000,
          title: `Error`,
          text: `Hubo un error cargando los registros, por favor revisar el formato del archivo.`,
          color: "danger",
        });
      }
    },
  },
};
</script>
<style scoped>
#select-prop {
  border: 1px solid #626262;
}
</style>