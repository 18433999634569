<template>
  <div class="w-full">
    <div class="p-5">
      <vs-row vs-type="flex" vs-justify="flex-end">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
        </vs-col>
      </vs-row>
    </div>
    <div v-show="true" id="data-list-thumb-view" class="data-list-container">
      <DataTable
        :headers="headers"
        :itemsData="warehouses"
        :itemPerPage="itemsPerPage"
        :totalItems="totalAssigned"
        @serverSideMethod="loadAssigned"
        :load="loading"
      >
      </DataTable>
    </div>
    <vs-popup :title="`Borrar asignación`" :active.sync="deletePopUpActive">
      <vs-row>
        <h5 class="w-100">
          Se eliminará la asignación del bodega
        </h5>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="danger"
            @click.native="deleteAssignment()"
            class="mt-2"
            icon-pack="feather"
            icon="icon-trash"
            >Sí, eliminar</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { propertyApi, propertySubscription } from "../services";
import currencies from "../../../helpers/currencies";
import DataTable from "../../../components/DataTable.vue";
import { sentryCaptureException } from "../../../helpers/Sentry";

export default {
  components: {
    DataTable
  },
  data() {
    return {
      loading: true,
      warehouses: [],
      itemsPerPage: 10,
      popupActive: false,
      headers: [],
      deletePopUpActive: false,
      property: null,
      totalAssigned: 20
    };
  },
  computed: {
    ...mapState("auth", ["user"])
  },
  async mounted() {
    const projectId = this.$store.getters["project/currentProject"];
    const totalAssigned = await propertyApi.totalAssignedList(
        projectId,
        "WAREHOUSE"
    );
    this.totalAssigned = totalAssigned;
  },
  beforeMount() {
    this.headers = [
      {
        text: "Unidad",
        value: "propertyByPropertyId.unit_number",
        width: 130,
        align: "left",
        cellRenderer: "composed",
        cellRendererParams: (params) => params.propertyByPropertyId.unit_number,
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Precio",
        value: "propertyByPropertyId.price",
        width: 170,
        align: "left",
        cellRenderer: "composed",
        filterType: "Number",
        cellRendererParams: (params) =>  this.getFormatCurrency(params.propertyByPropertyId.price),
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Estado",
        value: "propertyByPropertyId.status",
        width: 180,
        align: "left",
        cellRenderer: "chip",
        filterType: "Estado Compossed",
        filterValue: { type: null, value: "" },
        filter: true,
        filterStatus: [
          { name: "Disponible", value: "AVAILABLE" },
          { name: "Reservado", value: "RESERVED" },
          { name: "Asignado", value: "ASSIGNED" },
          { name: "Bloqueado", value: "BLOCKED" },
          { name: "Opcionado", value: "OPTIONED" },
          { name: "Prometido", value: "PROMISED" },
          { name: "Vendido", value: "SOLD" },
        ],
        cellRendererParams: (item) => this.getStatus(item.propertyByPropertyId),
      },
      {
        text: "Bodega Asignada",
        value: "property.unit_number",
        width: 130,
        align: "left",
        cellRenderer: "composed",
        cellRendererParams: (params) => params.property.unit_number,
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Precio Bodega",
        value: "property.price",
        width: 170,
        align: "left",
        cellRenderer: "composed",
        filterType: "Number",
        cellRendererParams: (params) =>  this.getFormatCurrency(params.property.price),
        filterValue: { type: null, value: "" },
        filter: true,
      },
      {
        text: "Estado Bodega",
        value: "property.status",
        width: 180,
        align: "left",
        cellRenderer: "chip",
        filterType: "Estado Compossed",
        filterValue: { type: null, value: "" },
        filterStatus: [
          { name: "Disponible", value: "AVAILABLE" },
          { name: "Reservado", value: "RESERVED" },
          { name: "Asignado", value: "ASSIGNED" },
          { name: "Bloqueado", value: "BLOCKED" },
          { name: "Opcionado", value: "OPTIONED" },
          { name: "Prometido", value: "PROMISED" },
          { name: "Vendido", value: "SOLD" },
        ],
        filter: true,
        cellRendererParams: (item) => this.getStatus(item.property),
      },
      {
        text: "Acciones",
        value: "actions",
        align: "left",
        cellRenderer: "actions",
        cellRendererParams: (item) => {
          const buttons = [];
          if (item.property.status != null) {
            if (item.property.status.id !== "RESERVED") {
              buttons.push({
                color: "danger",
                icon: "feather icon-trash",
                event: () => {
                  this.deleteEvent(item);
                },
              });
            }
          }
          return buttons;
        },
        sortable: false,
        width: 140,
      },
    ]
  },
  methods: {
    async loadAssigned(options) {
      const projectId = this.$store.getters["project/currentProject"];
      const vueScope = this;

      const { ordering, pagination, filtering } = options;
      
      const regex = /status:\{_eq:"([^"]+)"\}/
      
      const filtering_replace = filtering.replace(regex, "status:{_eq:$1}"); 
      
      const warehouses = await propertySubscription.onPropertyAssignedChange(
        projectId,
        "WAREHOUSE",
        ordering,
        pagination,
        filtering_replace
      );
      
      this.loading = true;
      warehouses.subscribe({
        next(p) {
          vueScope.warehouses = p.data ? p.data.assigned_property : [];
          vueScope.loading = false;
        },
        error(e) {
          sentryCaptureException(e, "warehouses");
        }
      });
    },
    getStatus(data) {
      return {
        color: data.status
          ? this.statusColor(data.status)
          : null,
        value: data.status ? data.property_status.name : null,
      };
    },
    statusColor(status) {
      if (status == "AVAILABLE") return "green";
      if (status == "RESERVED") return "red";
      if (status == "ASSIGNED") return "dark";
    },
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({
        value,
        currency: currencySymbol
      });
    },
    deleteEvent(data) {
      this.property = data.property;
      this.deletePopUpActive = true;
    },
    async deleteAssignment() {
      try {
        await propertyApi.deletePropertyAssigned(this.property.id);
        this.deletePopUpActive = false;

        this.$vs.notify({
          time: 6000,
          title: `Propiedad Asignada ${this.property.unit_number} 🏢🚀.`,
          text: `Eliminada correctamente.`,
          color: "success"
        });
      } catch (e) {
        this.$vs.notify({
          title: `No ha sido posible eliminar.`,
          text: `No ha sido posible eliminar la asignación de la propiedad.`,
          color: "danger"
        });
      }
    }
  }
};
</script>
